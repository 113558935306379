import { createAction, props, union } from '@ngrx/store';

export const SelectCustomer = createAction('[ContactFilter] SELECT_CUSTOMER', props<{ customerId: number }>());
export const SetFilterFormExpanded = createAction(
  '[ContactFilter] SET_FILTER_FORM_EXPANDED',
  props<{ expanded: boolean }>(),
);

export const ContactFilterActions = union({
  SelectCustomer,
  SetFilterFormExpanded,
});
