import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SelectCustomer, SetFilterFormExpanded } from './contact-filter.actions';

export const contactFilterFeatureKey = 'contact-filter';

export interface State {
  filterFormExpanded: boolean;
  selectedCustomerId: number | null;
}

export interface AppState {
  'contact-filter': State;
}

export const initialContactFilterState: State = {
  filterFormExpanded: false,
  selectedCustomerId: null,
};

const contactFilterReducer = createReducer(
  initialContactFilterState,
  on(SelectCustomer, (state, { customerId }): State => ({ ...state, selectedCustomerId: customerId })),
  on(SetFilterFormExpanded, (state, { expanded }): State => ({ ...state, filterFormExpanded: expanded })),
);

export function reducer(state: State | undefined, action: Action) {
  return contactFilterReducer(state, action);
}

export const selectContactFilterState = createFeatureSelector< State>(contactFilterFeatureKey);
export const selectSelectedCustomerId = createSelector(
  selectContactFilterState,
  (state: State) => state.selectedCustomerId as number,
);
export const selectFilterFormExpanded = createSelector(
  selectContactFilterState,
  (state: State) => state.filterFormExpanded,
);
